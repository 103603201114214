import React from 'react';
import '../styles/News.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TabTitle } from 'joonas.ninja-frontend-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ButtonContainer from '../view/ButtonContainer.jsx';
import LoadingSpinner from '../view/LoadingSpinner.jsx';
import * as NewsRequests from '../../util/newsRequests';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Util from '../../util/controllerUtil';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';

class NewsContentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headline: "",
            content: ""
        }
    }
    componentWillReceiveProps(props) {
        if (props.data && Object.keys(props.data).length > 0) {
            this.setState({ headline: props.data.headline, content: props.data.content });
        }
    }
    cancelChanges = async () => {
        this.setState({ headline: "", content: "" })
        this.props.closeCallback();
    }
    saveChanges = async () => {
        let payload = this.state;
        this.setState({ headline: "", content: "" })
        this.props.saveCallback(payload);
    }
    fieldChange = async (event, name) => {
        this.setState({ [name]: event.target.value });
    }
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.cancelChanges}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{this.props.data && Object.keys(this.props.data).length > 0 ? "Muokkaa uutista" : "Luo uutinen"}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        value={this.state.headline}
                        margin="dense"
                        id="headline"
                        label="Otsikko"
                        type="text"
                        onChange={(event) => (this.fieldChange(event, 'headline'))}
                        fullWidth />
                    <TextField
                        multiline={true}
                        autoFocus
                        value={this.state.content}
                        margin="dense"
                        id="content"
                        label="Sisältö"
                        type="text"
                        rows="4"
                        rowsMax="4"
                        onChange={(event) => (this.fieldChange(event, 'content'))}
                        fullWidth />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.cancelChanges} color="primary">
                        Peruuta
                    </Button>
                    <Button onClick={this.saveChanges} color="primary">
                        {this.props.data ? "Tallenna" : "Luo"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

class NewsTable extends React.Component {
    render() {
        if (this.props.loading) {
            return (<LoadingSpinner />)
        }
        if (this.props.data.length >= 1) {
            return (
                <Fade in={true}>
                    <Table className="table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Otsikko</TableCell>
                                <TableCell>Sisältö</TableCell>
                                <TableCell>Päivämäärä</TableCell>
                                <TableCell>Toiminnot</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.data.map(row => {
                                return (
                                    <TableRow key={row._id}>
                                        <TableCell style={{ maxWidth: 0 }} component="th" scope="row">
                                            <div style={{
                                                minWidth: "48px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}>
                                                {row.headline}
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ maxWidth: 0 }}><div style={{
                                            minWidth: "16px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}>{row.content}</div></TableCell>
                                        <TableCell>{Util.parseDate(row.createdDate)}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => (this.props.clickDeleteCallback(row))} aria-label="Delete" color="primary">
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton onClick={() => (this.props.clickUpdateCallback(row))} aria-label="Update" color="primary">
                                                <SettingsIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Fade>
            );
        }
        else {
            return (
                <Fade in={true}>
                    <div className="empty-table">
                        Et ole luonut vielä yhtään uutista.
                    </div>
                </Fade>
            );
        }
    }
}

class DeleteNewsModal extends React.Component {
    handleClose = async () => {
        this.props.closeCallback();
    }
    handleDelete = async () => {
        this.props.deleteCallback();
    }
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Poista uutinen</DialogTitle>
                <DialogContent>
                    Haluatko varmasti poistaa uutisen "{this.props.itemName}"?
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Ei
                    </Button>
                    <Button onClick={this.handleDelete} color="primary">
                        Kyllä
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

class News extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            newsData: [{
                _id: "",
                headline: "",
                content: "",
                createdDate: new Date()
            }],
            createModal: false,
            updateModal: false,
            deleteModal: false,
            selectedRow: {}
        }
    }
    async componentDidMount() {
        this.setState({ loading: true });
        try {
            let newsResponse = await NewsRequests.getNews();
            this.setState({ newsData: newsResponse, loading: false });
        } catch (error) {
            this.setState({ loading: false });
            console.log(error);
        }
    }
    openDeleteModalCallback = async (row) => {
        this.setState({ deleteModal: true, selectedRow: row });
    }
    openUpdateModalCallback = async (row) => {
        this.setState({ updateModal: true, selectedRow: row });
    }
    confirmDelete = async () => {
        try {
            this.setState({ loading: true });
            await NewsRequests.deleteNews(this.state.selectedRow._id);
            let newsResponse = await NewsRequests.getNews();
            this.setState({ loading: false, deleteModal: false, newsData: newsResponse, selectedRow: {} });
        } catch (error) {
            console.log(error);
        }
    }
    createModalSave = async (payload) => {
        try {
            this.setState({ loading: true });
            this.modalClosed();
            await NewsRequests.createNews(payload);
            let newsResponse = await NewsRequests.getNews();
            this.setState({ newsData: newsResponse, loading: false });
        } catch (error) {
            this.setState({ loading: false });
        }
    }
    updateModalSave = async (payload) => {
        try {
            this.setState({ loading: true });
            this.modalClosed();
            payload._id = this.state.selectedRow._id;
            await NewsRequests.updateNews(payload);
            let newsResponse = await NewsRequests.getNews();
            this.setState({ newsData: newsResponse, selectedRow: {}, loading: false });
        } catch (error) {
            this.setState({ loading: false });
        }
    }
    modalOpen = async () => {
        this.setState({ createModal: true })
    }
    modalClosed = async () => {
        this.setState({ createModal: false, updateModal: false, deleteModal: false })
    }
    render() {
        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Paper className="root">
                        <TabTitle title="Uutisten hallinta" headerSize={"h6"} borderStyle={"none"} borderWidth={"0px"} />
                        <div style={{ height: "416px", overflowY: "scroll", overflowX: "hidden" }}>
                            <NewsTable clickDeleteCallback={this.openDeleteModalCallback} 
                            clickUpdateCallback={this.openUpdateModalCallback} 
                            data={this.state.newsData} loading={this.state.loading} />
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <ButtonContainer>
                        <Button onClick={this.modalOpen} color="primary" variant="contained">Luo uutinen</Button>
                    </ButtonContainer>
                </Grid>
                <NewsContentModal open={this.state.createModal} closeCallback={this.modalClosed} saveCallback={this.createModalSave} />
                <NewsContentModal open={this.state.updateModal} data={this.state.selectedRow} closeCallback={this.modalClosed} saveCallback={this.updateModalSave} />
                <DeleteNewsModal itemName={this.state.selectedRow.headline} open={this.state.deleteModal} deleteCallback={this.confirmDelete} closeCallback={this.modalClosed} />
            </Grid>
        )
    }
}

export default News;
