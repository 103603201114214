import React from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import '../styles/Dashboard.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Const from '../../util/const.js';
import News from './News.jsx';
import IconButton from '@material-ui/core/IconButton';
import AccountBox from '@material-ui/icons/AccountBox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as User from '../../util/userRequests.js';
import { Notification } from 'joonas.ninja-frontend-components';

class UserDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    }
  }
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  logoutButton = async () => {
    this.handleClose();
    this.props.logoutCallback();
  }
  render() {
    const anchorEl = this.state.anchorEl;

    return (
      <div>
        <IconButton onClick={this.handleClick} color="inherit">
          <AccountBox />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}>
          <MenuItem onClick={this.handleClose}>Asetukset</MenuItem>
          <MenuItem onClick={this.logoutButton}>Kirjaudu ulos</MenuItem>
        </Menu>
      </div>
    );
  }
}

class DashboardContent extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={() => (<Redirect to="/news" />)} />
        <Route path="/news" component={News} />
      </Switch>
    );
  }
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      notificationMessage: "",
      notification: false
    }
  }
  logoutButton = async () => {
    try {
      await User.logout();
    } catch (error) {
      this.setState({ notificationMessage: "Virhe uloskirjautumisessa.", notification: true })
    }
  }
  render() {
    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h5" color="inherit" className="toolbar_typography">
              {Const.TITLE}
              <Button component={Link} to="/news" color="inherit">Uutiset</Button>
            </Typography>
            <UserDropdown logoutCallback={this.logoutButton} />
          </Toolbar>
        </AppBar>
        <DashboardContent />
        <Notification notificationMessage={this.state.notificationMessage} open={this.state.notification} />
      </div>
    );
  }
}

export default Dashboard;
