import React from 'react';
import Dashboard from './controller/Dashboard'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import secondaryColor from '@material-ui/core/colors/green';
import { BrowserRouter } from 'react-router-dom'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000"
    },
    secondary: secondaryColor
  },
  typography: {
    useNextVariants: true,
  }
});

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/ui">
        <MuiThemeProvider theme={theme}>
          <Dashboard />
        </MuiThemeProvider>
      </BrowserRouter>
    );
  }
}

export default App;
