import { HttpClient } from 'joonas.ninja-frontend-components';
import Const from './const';
import * as User from './userRequests.js';
const PATH = "/v1/news";

export async function createNews(payload) {
    await User.refreshSession();
    let response = await HttpClient("post", PATH, Const.API_PATH, { payload: payload });
    return response.data;
}

export async function getNews() {
    await User.refreshSession();
    let response = await HttpClient("get", PATH, Const.API_PATH);
    return response.data;
}

export async function updateNews(payload) {
    await User.refreshSession();
    let response = await HttpClient("put", PATH, Const.API_PATH, { payload: payload });
    return response;
}

export async function deleteNews(id) {
    await User.refreshSession();
    let response = await HttpClient("delete", PATH, Const.API_PATH, { params: { id: id } });
    return response;
}