import { HttpClient } from 'joonas.ninja-frontend-components';
import Const from './const';
const PATH = "/v1/user";

export async function logout() {
    let response = await HttpClient("get", PATH + "/logout", Const.API_PATH);
    window.location = response.data.redirectUrl;
    return response;
}

export async function refreshSession() {
    let response = await HttpClient("get", PATH + "/refreshSession", Const.API_PATH);
    if(response.status === 401){
        window.location = response.data.redirectUrl;
    }
    return response;
}